// Since this is an extension to the XF object itself we need to use jQuery.extend here.

import { XF } from "./XF";

// XF.Element.extend unfortunately only applies to elements registered with XF.Element.register.
$.extend(XF, {
  clearEditorContent(
    $container: Parameters<typeof XF.replaceEditorContent>[0],
    notConstraints: Parameters<typeof XF.replaceEditorContent>[3]
  ) {
    const ret = XF.replaceEditorContent($container, "", "", notConstraints);
    clearHiddenValues($container);

    $container.trigger("draft:sync");

    return ret;
  },
});

function clearHiddenValues($form: ReturnType<typeof $>) {
  const $hiddenInputs = $form.find(
    'input[name="parent_id"], input[name="parent_ids"]'
  );
  $hiddenInputs.val("");
}
